import React from 'react';
import Chest from "../assets/img/chest-404.png";

const PageNotFound = () => {
  return (
    <div id="page-404" className="page fade-onload">
        <div className="container-custom">
            <div className="visible-xs">
                <img src={Chest} alt="404" className="chest" />
            </div>
            <div className="hidden-xs">
                <div className="chest"></div>
            </div>
            <div className="content">
                <h1 className="page-heading fade-onload">
                Page not found
                </h1>
                <p>
                    This chest is&nbsp;empty. <br /> The site you are looking for can not appear on&nbsp;the maps. <br /> Continue to&nbsp;homepage.
                </p>
                
                <div className="btn-wrap" style={{ marginTop: "60px" }}>
                    <a href="/" className="btn btn-primary">Homepage</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PageNotFound;
