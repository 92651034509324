import React, { useContext, useState } from 'react';
import { faqData } from '../assets/dummyData/faq';
import { NewsLetterContentFaq } from '../assets/dummyData/NewsLetterData';
import Faq from '../components/Faq/Faq';
import NewLetter from '../components/NewLetter/NewLetter';
import { Context } from '../context/Products';
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';
import Helmet from "../components/Helmet"

const FaqPage = () => {
  const [{ lang }] = useContext(Context);
  const [shoeModalEmail, setShoeModalEmail] = useState(false);

  const modalEmail = () => {
    setShoeModalEmail(true);
    const body = document.body;
    body.classList.add("modal-open");
    setTimeout(() => {
        setShoeModalEmail(false);
    }, 500);
  }

  return (
    <div id="page-faq" className="page">
        <Helmet
          title="Faq | Captain Candy"
          metaDesc="Bei Captain Candy bieten wir Bonbons von bester Qualität an. Du findest leckere Gelees, ausgesuchte Pralinen, tolle Lakritzbonbons, Marshmallows, aber auch Kaugummis und Lutscher. Jeder wählt wirklich."
        />
        <Faq data={faqData[lang]} title={faqData?.title[lang]} />
        <NewLetter hiddeImg data={NewsLetterContentFaq[lang]} openModal={modalEmail} />
        <ModalEmailSuccess open={shoeModalEmail} />
    </div>
  )
}

export default FaqPage;