import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const career = {
    en: {
        title: "Jobs currently available with us",
        accordionTitle: "Sales assistant",
        accordionSubTitle: "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
        accordion: [
            {
                id: "1",
                title: "Sales assistant",
                content:  "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
                item: [
                    {
                        title: "Why sail with us?",
                        list: [
                            "Our boat is anchored right in the heart of Cologne",
                            "No matter how big the wave, we can’t be overturned, we’re a stable Czech company",
                            "Our sailors get to work flexible shifts",
                            "There’s a friendly atmosphere among members of the crew ",
                            "We offer fair and motivating pay",
                            "Opportunity to earn extra pay as part of our bonus programme",
                            "Opportunities to climb the career ladder with us – from sailor all the way to admiral",
                            "Get to choose the length of your expedition  - full or part-time hours",
                            "We’ll train you up on everything you need to know to become a fully-fledged crew member",
                        ]
                    },
                    {
                        title: "What are our expectations once you’re on board?",
                        list: [
                            "Friendly and positive behaviour when communicating with the natives as well as foreigners",
                            "Willingness to learn new skills",
                            "Flexibility to weather all storms",
                            "To work with zeal and have a healthy work ethic.",
                        ]
                    },
                    {
                        title: "What kind of work awaits our eager new recruits?",
                        list: [
                            "To help and serve everyone the tide brings in",
                            "To keep the trunks and barrels fully stocked with sweet treats",
                            "To pop down to the lower decks to pick up supplies",
                            "To scrub the decks and captain’s bridge every now and then after a hard day’s work",
                        ],
                    },
                ],
                itemFooter: "If you’re interested and keen to get on board with us, be ready to set sail right away!",
                tabel: <Fragment>
                        <table>
                            <thead><tr><td></td><td></td></tr></thead>
                            <tbody>
                                <tr>
                                    <td>E-mail:</td>
                                    <td>
                                        <a href="mailto:info@captaincandy.at "><b>info@captaincandy.at</b></a>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>Mobile:</td>
                                    <td><a href="tel:+4915736559833"><b>+49 157 36559833</b></a></td>
                                </tr> */}
                                {/* <tr>
                                    <td>Phone number:</td>
                                    <td><a href="tel:+4922142322751"><b>+49 221 42322751</b></a></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </Fragment>
            },
        ],
        itemFooter: "If you’re interested and keen to get on board with us, be ready to set sail right away!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Covering letter",
                textarea: true,
            },
            {
                id: "cv",
                name: "cv",
                label: "CV",
                placeholder: "Browser",
                file: true,
            },
        ],
        link: <Fragment>“I consent to the <Link onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send message"
    },
    nl: {
        accordion: [
            {
                id: "1",
                title: " Where do the sweets in Captain Candy’s stores come from?", 
                content: "Captain Candy’s ships travel all over Europe (from Spain to Germany, from Sweden to Italy) to the very best suppliers for candy. All you have to do is look at the barrel or the box and that will tell you where the particular candy has sailed from."
            },
        ],
        title: "Jobs currently available with us",
        accordionTitle: "Sales assistant",
        accordionSubTitle: "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
        item1: <Fragment>
                <div className="item-inner">
                    <h4 className="heading">
                        Why sail with us?
                    </h4>
                    <ul>
                        <li>Our boat is anchored right in the heart of Köln</li>
                        <li>No matter how big the wave, we can’t be overturned, we’re a stable Czech company</li>
                        <li>Our sailors get to work flexible shifts </li>
                        <li>There’s a friendly atmosphere among members of the crew </li>
                        <li>We offer fair and motivating pay </li>
                        <li>Opportunity to earn extra pay as part of our bonus programme </li>
                        <li>Opportunities to climb the career ladder with us – from sailor all the way to admiral</li>
                        <li> Get to choose the length of your expedition  - full or part-time hours</li>
                        <li>We’ll train you up on everything you need to know to become a fully-fledged crew member</li>
                    </ul>
                </div>
            </Fragment>,
        itemFooter: "If you’re interested and keen to get on board with us, be ready to set sail right away!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Naam en achternaam",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefoon nummer",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Begeleidende brief",
                textarea: true,
            },
            {
                id: "cv",
                name: "cv",
                label: "cv",
                placeholder: "Bladeren",
                file: true,
            },
        ],
        link: <Fragment>“Ik stem in met de <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">verwerking van mijn persoonsgegevens</Link> en de antwoorden op de vragenlijst.</Fragment>,
        button: "Bericht versturen"
    },
    de: {
        title: "Aktuell offene Stellen",
        accordionTitle: "Verkäufer*in (m/w/d)",
        accordionSubTitle: "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
        accordion: [
            {
                id: "1",
                title: "Verkäufer*in (m/w/d)",
                content:  "Segeln Sie mit uns auf ein Piraten-Abenteuer und helfen Sie anderen Ihren Weg zu ihrem eigenen Schatz zu finden! Es gibt niemals genug zuverlässige Segelnde. und wir belohnen ehrliche Arbeit mit Gold während der Reise!",
                item: [
                    {
                        title: "Warum mit uns segeln?",
                        list: [
                            "Unser Schiff ankert im Herzen von Prag",
                            "Egal wie groß die Welle ist, wir sind ein stabiles tschechisches Unternehmen, das nicht überrollt werden kann.",
                            "Unsere Segelnden arbeiten in flexiblen Schichten.",
                            "Es herrscht eine freundliche Atmosphäre zwischen der Besatzung",
                            "Wir bieten eine motivierende und faire Bezahlung",
                            "Möglichkeit, im Rahmen unseres Bonusprogramms zusätzliches Geld zu verdienen",
                            "Wir bieten einen Karrierewechsel - vom Matrosen zum Admiral",
                            "Wählen Sie die Länge des Segeltörns - Voll- oder Teilzeit",
                            "Wir schulen Sie in allem, was für die Schiffscrew benötigt wird",
                        ],
                    },
                    {
                        title: "Was erwarten wir, wenn Sie an Bord sind?",
                        list: [
                            "Ein angenehmes und positives Verhalten im Umgang mit Einheimischen und Ausländer*innen",
                            "Bereitschaft, neues zu lernen",
                            "Flexibilität bei jedem Sturm",
                            "Arbeitseifer und eine gesunde Arbeitsmoral",
                        ]
                    },
                    {
                        title: "Welche Art von Arbeit wartet auf unsere eifrigen neuen Mitarbeiter*innen?",
                        list: [
                            "Zu helfen und zu bedienen, wen immer die Flut bringt",
                            "Die Truhen und Fässer mit süßen Leckereien zu füllen",
                            "Auf die unteren Decks zu gehen, um Vorräte zu holen",
                            "Ab und zu nach einem harten Arbeitstag die Decks und die Kapitänsbrücke zu schrubben",
                        ],
                    },
                ],
                itemFooter: "Wenn Sie interessiert sind und mit uns an Bord gehen wollen, sollten Sie sofort die Segel setzen!",
                tabel: <Fragment>
                        <table>
                            <thead><tr><td></td><td></td></tr></thead>
                            <tbody>
                                <tr>
                                    <td>E-mail:</td>
                                    <td>
                                        <a href="mailto:info@captaincandy.at"><b>info@captaincandy.at</b></a>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>Mobiel:</td>
                                    <td><a href="tel:+4915736559833"><b>+49 157 36559833</b></a></td>
                                </tr> */}
                                {/* <tr>
                                    <td>Telefoonnummer:</td>
                                    <td><a href="tel:+4922142322751"><b>+4922142322751</b></a></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </Fragment>
            },
        ],
        itemFooter: "Wenn Sie interessiert sind und bei uns einsteigen, sollten Sie wissen, dass wir sofort ablegen!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name und Nachname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefon",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Motivationsschreiben",
                textarea: true,
            },
            {
                id: "cv",
                name: "cv",
                label: "Lebenslauf",
                placeholder: "Datei auswählen",
                file: true,
            },
        ],
        link: <Fragment>Ich stimme der <Link onClick={() => toTop()} to="/personal-data-processing" className="link">Verarbeitung personenbezogener Daten</Link> zu.</Fragment>,
        button: "Nachricht absenden"
    },
};

export {
    career,
};