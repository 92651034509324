import { Fragment } from "react";
import { Link } from "react-router-dom";

const faqData = {
    title: {
        en: "Frequently asked questions",
        de: "Häufig gestellte Fragen",
    },
    en: [
    	{
            id: "1",
            title: "Where do the sweets in Captain Candy’s stores come from?", 
            content: "Captain Candy’s ships travel all over Europe (from Spain to Germany, from Sweden to Italy) to the very best suppliers for candy. All you have to do is look at the barrel or the box and that will tell you where the particular candy has sailed from."
        },
        {
            id: "2",
            title: " What is the shelf life of the candy?", 
            content: "As we pirates say, “You can find the answer to everything in a barrel”. In this case not actually in the barrel, but on the barrel. The candy’s shelf life is written on each of them.",
        },
        {
            id: "3",
            title: "How should the candy be stored after it has been bought?", 
            content: "In the same way as supplies on pirate expeditions - if possible in dry and cold conditions",
        },
        {
            id: "4",
            title: "Is it okay that the candy in the stores lies in open barrels?", 
            content: "Although pirates often lived outside the law, everything’s all right here. It’s called “free sale” and doesn’t break hygiene rules in any way. It’s like when you put bread in a bag in a port’s “super” market…",
        },
        {
            id: "5",
            title: "Where does Captain Candy come from?", 
            content: "The first Captain Candy store was opened in Prague and after this success we decided to expand further. You can currently find us in several countries and we are planning more!",
        },
        {
            id: "6",
            title: "Why can’t we try the candy in the barrels?", 
            content: "Unfortunately, it’s not possible for hygiene reasons. But don’t be afraid to ask our crews in the stores – they will be happy to help you find the exact taste you are looking for.",
        },
        {
            id: "7",
            title: "Is it hygienic to put tongs on barrels? ", 
            content: "The old pirate rule states that “tongs belong on a barrel”. What’s more, here too we respect the law which doesn’t see any problem with this.",
        },
        // {
        //     id: "8",
        //     title: "How many stores does Captain Candy have?", 
        //     content: "So far we have dropped anchor in four places in Prague and we have two harbours each in Dubrovnik in Croatia and in Venice in Italy. And we are planning more expeditions!",
        // },
        {
            id: "9",
            title: "Does the Captain Candy concept also work as a franchise?", 
            content: <Fragment>Yes, the Captain Candy concept successfully operates several franchise stores. If you are interested in cooperation, please contact us via the form in the <Link to="/franchising">&quot;Franchising&quot</Link>; section.</Fragment>
        },
        {
            id: "10",
            title: "Are the barrels really completely full?", 
            content: "Pirates must have some secrets. And that’s why we’ll leave it to you to guess.",
        },
        {
            id: "11",
            title: "Which is the most popular candy?", 
            content: "Not even the famous sorceress from the Island of Crystal Balls could answer that. Everyone chooses whatever suits their taste and the best-selling candy often changes. If you look at the barrels in the stores you will find out more.",
        },
        {
            id: "12",
            title: "What are the stores’ opening hours?", 
            content: <Fragment>From Monday to Friday we take in every traveller from 10 in the morning till 11 at night. At weekends between 10 o’clock in the morning and midnight. You can look in detail here. <Link to="/stores">here</Link>.</Fragment>,
        },
        {
            id: "13",
            title: " Why isn’t the price of the candy written on each barrel?", 
            content: " Because there’s no need. ;) The price per 100 g is uniform for the entire selection and you will always find it written in the store.",
        },
    ],
    de: [
    	{
            id: "1",
            title: "Woher kommen die Süßigkeiten in den Geschäften von Captain Candy?", 
            content: "Für die Süßigkeiten fahren die Schiffe von Captain Candy quer durch Europa (von Spanien bis Deutschland, von Schweden bis Italien) zu den besten Lieferanten. Sie brauchen nur auf das Fass oder die Kiste zu schauen, und schon wissen Sie, von wo aus die jeweilige Süßigkeit hergesegelt ist."
        },
        {
            id: "2",
            title: "Wie lange sind die Süßigkeiten haltbar? ", 
            content: 'Wie wir Piraten sagen: "Du findest die Antwort auf alles im Fass.“ Also in diesem Fall nicht direkt im Fass, sondern auf dem Fass. Die Haltbarkeit der Süßigkeiten steht auf jedem von ihnen.',
        },
        {
            id: "3",
            title: "Wie müssen die Süßigkeiten nach dem Kauf aufbewahrt werden?", 
            content: "Genauso wie Vorräte auf Piraten-Expeditionen – am besten trocken und kalt.",
        },
        {
            id: "4",
            title: "Ist es in Ordnung, dass die Süßigkeiten in Geschäften auf offenen Fässern liegen?", 
            content: "Obwohl Piraten oft gesetzlos handeln, ist hier alles in Ordnung. Das nennt sich „freier Verkauf“ und widerspricht in keiner Weise den Hygienestandards. Es ist, als würde man auf dem Hafenmarkt Brot in Tüten kaufen…",
        },
        {
            id: "5",
            title: "Woher kommt Captain Candy?", 
            content: "Der erste Captain Candy-Laden wurde in Prag eröffnet und nach diesem Erfolg haben wir uns entschlossen, weiter zu expandieren. Sie können uns derzeit in mehreren Ländern finden und wir planen noch mehr!",   
        },
        {
            id: "6",
            title: "Warum kann man keine Süßigkeiten aus Fässern probieren?", 
            content: "Dies ist aus hygienischen Gründen leider nicht möglich. Aber scheuen Sie sich nicht, unsere Besatzung direkt in den Geschäften zu fragen – sie helfen Ihnen gerne dabei, genau den Geschmack zu finden, den Sie suchen.",
        },
        {
            id: "7",
            title: "Ist es hygienisch, Zangen auf die Fässer zu legen? ", 
            content: 'Eine alte Piraten-Regel besagt, dass "Zangen auf ein Fass gehören". Darüber hinaus respektieren wir auch hier das Gesetz, das darin kein Problem sieht.',
        },
        // {
        //     id: "8",
        //     title: "Wie viele Geschäfte hat Captain Candy?", 
        //     content: "Bisher sind wir an mehreren Orten in Prag, Venedig und Verona in Italien vor Anker gegangen. Wir sind jetzt auch in Amsterdam in den Niederlanden und bereiten Expeditionen vor!",
        // },
        {
            id: "9",
            title: "Funktioniert das Captain Candy-Konzept auch als Franchise?", 
            content: <Fragment>Ja, das Captain Candy-Konzept betreibt erfolgreich mehrere Franchise-Läden. Wenn Sie an einer Zusammenarbeit interessiert sind, kontaktieren Sie uns bitte über das Formular im Abschnitt <Link to="/franchising">&quot;Franchising&quot;</Link> Abschnitt.</Fragment>,
        },
        {
            id: "10",
            title: "Sind die Fässer wirklich voll?", 
            content: "Piraten müssen ein paar Geheimnisse haben, und genau das lassen wir Sie erraten :-)",
        },
        {
            id: "11",
            title: "Welche Süßigkeiten sind die beliebtesten?", 
            content: "Dies konnten nicht einmal die bekannten Zauberer von der Insel der Kristallkugeln beantworten. Jeder wählt nach seinem Geschmack und die meistverkauften Süßigkeiten ändern sich oft. Sie erfahren mehr, wenn Sie in den Shops einen Blick in die Fässer werfen.",
        },
        {
            id: "12",
            title: "Wie sind die Öffnungszeiten der Geschäfte?", 
            content: <Fragment>Jedes Geschäft handelt nach seinen eigenen Piraten-Prinzipien. Die Details können Sie <Link to="/stores">hier</Link>nachsehen.</Fragment>,
        },
        {
            id: "13",
            title: "Warum steht nicht auf jedem Fass der Preis für die Süßigkeiten?", 
            content: "Weil es nicht nötig ist. ;) Der Preis pro 100g ist für das gesamte Sortiment einheitlich und steht immer im Geschäft angeschrieben.",
        },
    ],
};

const dataProcessing = {
    en: {
        title: "Personal data processing",
        parag: [
            'Al fine di proteggere i dati personali, acconsento, in qualità di parte interessata dei dati personali, alla raccolta, conservazione e trattamento dei seguenti dati personali: nome, cognome, indirizzo di posta elettronica, indirizzo di residenza, data di nascita, numero del telefono cellulare, informazioni sul titolo di studio e altre informazioni volontariamente fornite nel questionario inviato, da parte del titolare dei dati personali, ossia: GESSEN AT GmbH Gertrude-Fröhlich-Sandner-Straße 1/13 A-1100 Vienna FN490943a.',
            "This consent is granted for an indefinite period until the subject withdraws his/her consent.",
            "Lo scopo del trattamento dei dati personali dell'interessato stabilito dal titolare è quello di utilizzare i dati personali solo per l'eventuale instaurazione di una cooperazione commerciale con l'interessato.",
            "Il titolare dichiara che raccoglierà i dati personali nella misura necessaria per l'adempimento delle finalità sopra indicate e che li tratterà solo in conformità con lo scopo per cui sono stati raccolti. Il titolare dichiara che i dati personali saranno trattati nel modo seguente: a) meccanicamente (automaticamente), via computer e software nell'ambito del sistema informatico; b) manualmente.",
            "Inoltre, dichiaro di essere stato informato e di conoscere l'informativa sulla privacy del titolare.",
        ]
    },
    de: {
        title: "Verarbeitung personenbezogener Daten",
        parag: [
            'Im Sinne des Schutzes personenbezogener Daten, stimme ich als betroffene Person der Erhebung, Speicherung und Verarbeitung meiner personenbezogenen Daten zu: Vorname, Nachname, E-Mail-Adresse, Wohnanschrift, Geburtsdatum, Handynummer, Angaben zur höchsten Bildung und andere Informationen. Ich habe den zugesandten Fragebogen freiwillig ausgefüllt und gebe hiermit den Administrator der personenbezogenen Daten an, die: GESSEN AT GmbH Gertrude-Fröhlich-Sandner-Straße 1/13 A-1100 Wien FN490943a.',
            "Diese Zustimmung wird auf unbestimmte Zeit erteilt, bis die Person seine/ihre Zustimmung zurückzieht.",
            "Der vom Administrator festgelegte Zweck der Verarbeitung personenbezogener Daten, für den die betroffene Person diese Einwilligung erteilt, ist die Verwendung der personenbezogenen Daten nur für die mögliche Aufnahme einer geschäftlichen Zusammenarbeit mit der betroffenen Person.",
            "Der Administrator erklärt, dass er personenbezogene Daten in dem zur Erfüllung des oben genannten Zwecks erforderlichen Umfang erhebt und nur entsprechend dem Zweck verarbeitet, für den sie erhoben wurden. Der Administrator erklärt, dass er personenbezogene Daten wie folgt verarbeiten wird: a) maschinell (automatisiert), durch Computer und Computerprogramme innerhalb des Informationssystems; b) manuell.",
            "Diese Einwilligung ist eine freie und bewusste Willensentscheidung der betroffenen Person, deren Inhalt die Einwilligung meiner Person als Betroffene/r in die Verarbeitung der oben genannten personenbezogenen Daten ist. Mir ist bekannt, dass ich diese Einwilligung jederzeit widerrufen kann. Hiervon unberührt bleibt das Recht des Administrators (oder des/der Sachbearbeiter*in), personenbezogene Daten zu verarbeiten, wenn deren Verwendung für die Erbringung der Dienstleistung erforderlich ist bzw. zum Zwecke der Vertragserfüllung und soweit gesetzlich zulässig.",
            "Ich bestätige ferner, dass ich mit den Grundsätzen des Schutzes und der Verarbeitung personenbezogener Daten des Administrators informiert und vertraut bin.",
        ],
    },
}

export {
    faqData,
    dataProcessing,
};