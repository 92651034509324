import React, { useContext } from 'react';
import { homePageData, promoTextHeader } from '../assets/dummyData/homePage';
import { boxes, HpMapData } from '../assets/dummyData/HpBoxesData';
import { locations } from '../assets/dummyData/map';

import Header from '../components/Header/Header';
import HpAbout from '../components/HpAbout/HpAbout';
import HpBoxes from '../components/HpBoxes/HpBoxes';
import HpMap from '../components/Hpmap/HpMap';
import CommingSoon from '../components/CommingSoon/CommingSoon';
import { Context } from '../context/Products';
import Helmet from "../components/Helmet";

const HomePage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <div id="page-hp" className="page">
        <Helmet
          title="Süßwarenladen-Kapitän Candy"
          metaDesc="Bei Captain Candy bieten wir Süßwaren von höchster Qualität. Sie finden köstliche Gelees, sortiert Pralinen, tolle Lakritzbonbons, Marshmallows, aber auch Kaugummi und Lutscher. Es gibt etwas für jeden."
        />
        <Header promoText={promoTextHeader[lang]} />
        <CommingSoon />
        <HpMap data={HpMapData[lang]} locations={locations[lang]} />
        <HpAbout
          data={homePageData[lang]}
        />
        <HpBoxes
          boxes={boxes[lang]}
          title={boxes.title[lang]}
        />
        <div className="clearfix" />
        {/* <HpMap data={HpMapData[lang]} locations={locations[lang]} /> */}
    </div>
  )
}

export default HomePage;
