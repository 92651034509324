import React, { useContext } from 'react';
import { CaptainStoryData } from '../assets/dummyData/CaptainStoryData';
import CaptainStory from '../components/CaptainStory/CaptainStory';
import { Context } from '../context/Products';

const CaptainStoryPage = () => {
  const [{ lang }] = useContext(Context)
  return (
    <CaptainStory data={CaptainStoryData[lang]} />
  )
}

export default CaptainStoryPage;