import React, { useState } from 'react';
import axios from "axios";
import { validateEmail, validatePhone, validFields } from '../../assets/function';
import { axiosLink } from '../../axiosLink';
import Helmet from '../Helmet';
import { SentEmail } from './SentEmail';
import LoaderMail from '../LoaderMail/LoaderMail';

const Contact = ({ data }) => {
    const [sendMail, setSendMail] = useState(true);
    const [agreement, setAgreement] = useState(false);
    const [disabledSend, setDisabledSend] = useState(true);
    const [required, setRequired] = useState(false);
    const [formData, setFormData] = useState({
        contactform_fullName: "",
        contactform_email: "",
        contactform_text: "",
    });

    const validInputs = (e) => {
        if (e === "contactform_email") {
            const valid = validateEmail(formData.contactform_email);
            if (valid) {
                return false;
            } else {
                return true;
            }
        } else if (e === "contactform_phone") {
            const valid = validatePhone(formData.contactform_phone);
            if (valid || !formData?.contactform_phone) {
                return false;
            } else {
                return true;
            }
        } else {
            if (formData[e]?.length > 2) {
                return false;
            } else {
                return true;
            }
        }
    }

    const handleScroll = (to) => {
        const section = document.getElementById( to );
        section.scrollIntoView( { behavior: 'smooth'} );
    };

    const onchange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const sendEmail = (e) => {
        e.preventDefault();
        const fillAllFields = validFields(formData);
        if (fillAllFields) {
            setRequired(true);
            handleScroll(fillAllFields);
            return;
        }
        const valid = validateEmail(formData.contactform_email);
        if (!valid) {
            setRequired(true);
            handleScroll("contactform_email");
            return;
        }
        const validPhone = validatePhone(formData.contactform_phone);
        if (!validPhone && formData.contactform_phone) {
            setRequired(true);
            handleScroll("contactform_phone");
            return;
        }

        if (agreement) {
            setDisabledSend(false);
            const data = { ...formData, state: "at" };
            axios.post(
                `${axiosLink}/mail/contact`,
                data,
            )
            .then(res => {
                if (res) {
                    setDisabledSend(true)
                    setFormData({
                        contactform_fullName: "",
                        contactform_email: "",
                        contactform_text: "",
                    });
                    setSendMail(false);
                }
            })
            setRequired(false);
        } else {
            setRequired(true);
        }
    }
    return (
        <div id="page-kontakt" className="page">
            <Helmet
                title="Neem contact met ons op | Captain Candy"
                metaDesc="Bij Captain Candy bieden we zoetwaren van de beste kwaliteit. U vindt er heerlijke geleien, diverse chocolaatjes, heerlijke dropsnoepjes, marshmallows, maar ook kauwgom en lolly's. Er is iets voor iedereen."
            />
            <header id="main"></header>
            <div className="content">
                <div className="container-custom">
                    <h1 className="page-heading fade-onload">
                        {data?.title}
                    </h1>
                    <p className="fade-onload">
                        {data?.history}
                    </p>
                    <br />
                    <p className="fade-onload">
                        {data?.historyLink}
                    </p>
                </div>
                {/* <!-- form --> */}
                <h2 className="heading aos-init aos-animate" data-aos="fade-up" id="kontakt">
                    {data?.questionTitle}
                </h2>
                {sendMail ? (
                    <form id="contact_form" onSubmit={sendEmail} className="validate aos-init aos-animate">
                        {data?.inputs?.map((element) => (
                            <div className={`form-group  has-feedback ${(required && (validInputs(element.name))) && "has-error has-danger"}`} key={element.id}>
                                <label  className="textarea" htmlFor={element.id}>{element.label}</label>
                                <div className="berlicka">
                                    {element?.textarea ? (
                                            <textarea name={element.name} id={element.id} onChange={onchange} className="form-control" rows="4"></textarea>
                                        )
                                        : (
                                            <input name={element.name} type="text" onChange={onchange} className={`${element?.class} form-control`} id={element.id} placeholder="" required="" />
                                        )}
                                    <span className="valid-icon"></span>
                                </div>
                            </div>
                        ))}
                        <div className="clearfix"></div>
                        <div className={`form-group agree ${(required && !agreement) && "has-error has-danger"}`}>
                            <label></label>
                            <div className="checkbox-wrap">
                                <input type="checkbox" id="checkbox" checked={agreement} name="agreement"  onChange={() => setAgreement(!agreement)} />
                                <label htmlFor="checkbox" className="circle"></label>
                                <label htmlFor="checkbox" className="label">{data?.link}</label>
                            </div>
                        </div>
                        <button type="submit" className={`btn btn-primary ${(!agreement || !disabledSend) && "disabled"}`} disabled={!agreement}>{disabledSend ? data?.button : <LoaderMail /> }</button>
                    </form>
                ) : (
                    <SentEmail sendAnotherEmail={(e) => setSendMail(e)} />
                )}

        

            <div className="msg-send not-send hidden" id="contact_form_send_error">
                <span className="ttl">
                    E-mail was not sent
                </span>
                <p>
                    Something went wrong, please contact us in another way
                </p>
                <button type="submit" className="btn btn-primary" data-contactform-resend="1">Try again</button>
            </div>
            
            </div>
        </div>
    )
}

export default Contact;
