import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { footerMenu } from '../../assets/dummyData/Menu';
import Logo from "../../assets/img/brand-logo.svg";
import { Context } from '../../context/Products';
import "./style.css";
// import Social from './Social';

const Footer = ({ activeClass }) => {
  const [{ lang }] = useContext(Context);

  return (
    <footer id="main" className="container-custom-footer">
      <div className="left left-content around-content">
        <img src={Logo} alt="Captain Candy" className="brand-logo" />
        <div>
          {/* <Social lang={lang} classProp="right social-links around-content hidden-lg" /> */}
          <span className="copyright hidden-sm hidden-md">
              ©&nbsp;2023&nbsp;Captain&nbsp;Candy
          </span>
        </div>
      </div>
      <ul className='footer-menu'>
          {footerMenu[lang].map((el) => (
              <li
                  key={el.path} className={(activeClass === el.path) ? "active" : ""}
              >
                  <Link
                      onClick={() => {
                          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                      }}
                      to={el.path}
                  >
                      {el.name}
                  </Link>
              </li>
          ))}
      </ul>
      {/* <Social lang={lang} classProp="right social-links around-content hidden-xs hidden-sm hidden-md" /> */}
    </footer>
  )
}

export default Footer;