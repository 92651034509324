import React,{ useContext } from 'react';
import Franchising from '../components/Franchising/Franchising';
import { franchisingData } from "../assets/dummyData/franchisingData";
import { Context } from '../context/Products';

const FranchisingPage = () => {
  const [{ lang }] = useContext(Context)
  return (
    <Franchising data={franchisingData[lang]} />
  )
}

export default FranchisingPage;