import React from 'react';
import AboutBg from "../../assets/img/about_bg.jpg";
// import AboutBg from "../../assets/img/web/about_bg.png";
import aboutCompass from "../../assets/img/about_compass.png";
import aboutCompassInner from "../../assets/img/about_compass_inner.png";
import about768 from "../../assets/img/768x1.png";
import aboutMin10 from "../../assets/img/about/10-min.png";

import '../HpAbout/about.css';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';
import { routeImgData } from '../../assets/dummyData/About';
import { Fragment } from 'react';


const About = ({ aboutData, footerText }) => {
    const elementsToAnimate = ['footerText', "step0", "step1", "step2", "step3", "step4", "step5", "step6", "step7", "step8", "step9"];
    const { singleRefs } = AnimationEffects(elementsToAnimate, routeImgData, 'fadeIn', 0.01, 0.08);

  return (
    <>
        <picture>
            {/* <!--[if IE 9]><video style="display: none;"><![endif]--> */}
            <source media="(min-width: 768px)" srcSet={AboutBg} />
            <source media="(max-width: 767px)" srcSet={about768} />

            {/* <!--[if IE 9]></video><![endif]--> */}
            <img src={AboutBg} id="bg" alt="..." />
        </picture>


        <div id="compass-wrap">
            <img src={aboutCompass} alt="compass" className="compass" />
            <img src={aboutCompassInner} alt="compass" className="compass spinnerCompass" id="compass" />
        </div>

        <div className="hidden-xs">
            <div id="elements" data-aos="fade-in" className="aos-init aos-animate"></div>
        </div>
        {/* <!--<div id="path"></div>--> */}
        <div className="steps-wrap">
            <div className="hidden-xs">
                {routeImgData?.map((element, index) => (
                    <img
                        key={element.step}
                        src={element.imgAbout}
                        className={`opacity-0 step step-${1 + index}`}
                        alt="step"
                        ref={singleRefs.elements[element.step].ref}
                    />
                ))}
            </div>
            <div className="content">
                <div className="items-wrap">
                    {aboutData?.map((el, index) => (
                        <div key={el.year} className="item aos-init aos-animate opacity-0" ref={singleRefs.items[index].ref}>
                            <div className="img-wrap visible-xs">
                                <img src={el.img} srcSet={el.img} alt="elemety" />
                            </div>
                            <h2 className="heading">
                                {el.year}
                                <img src={el.yearImg} alt={el.year} title={el.year} />
                            </h2>
                            {el?.title && <span className="ttl">
                                <Fragment>{el?.title}</Fragment> 
                            </span>}
                            <p>{el.description}</p>
                        </div>
                    ))}
                    {/* <!-- last --> */}
                    <div className="item last aos-init aos-animate" data-aos="fade-in" data-aos-anchor=".step-10" data-aos-delay="400">
                        <div className="img-wrap visible-xs">
                            <img src={aboutMin10} srcSet={aboutMin10} alt="elementy" />
                        </div>
                        <span data-aos="fade-in" className="aos-init aos-animate" ref={singleRefs.elements.footerText.ref}>
                            {footerText}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default About;