import React, { useContext } from 'react';
import { dataProcessing } from '../assets/dummyData/faq';
import Faq from '../components/Faq/Faq';
import { Context } from '../context/Products';

const PersonalDataProcessingPage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <div id="page-faq" className="page">
        <Faq dataProcessing={dataProcessing[lang].parag} title={dataProcessing[lang].title} />
    </div>
  )
}

export default PersonalDataProcessingPage;
