import mapMarker from "../img/icons/map_marker.svg";

const locations = {
    en: [
            {
                id: "1",
                position: { lat: 48.19815215609203, lng: 16.36330847568331 },
                title: "Austria",
                link: "https://maps.app.goo.gl/Mmk6bKNdrDA8UE2V7",
                icon: mapMarker,
                table: [
                    {
                        open: "Shopping Centers",
                    },
                    {
                        open: "Main Streets",
                    },
                    {
                        open: "High Traffic Areas",
                    },
                ]
            },
        ],
    de:  [
            {
                id: "1",
                position: { lat: 48.19815215609203, lng: 16.36330847568331 },
                title: "Österreich",
                link: "https://maps.app.goo.gl/Mmk6bKNdrDA8UE2V7",
                icon: mapMarker,
                table: [
                    {
                        open: "Einkaufszentren",
                    },
                    {
                        open: "Hauptstraßen",
                    },
                    {
                        open: "Hochfrequentierte Bereiche",
                    },
                ]
            },
        ],
    nl:  [
        {
            id: "1",
            position: { lat: 50.9384700537516, lng: 6.956519442330022 },
            title: "Hohe Strasse 120-122",
            link: "https://www.google.com/maps/place/Captain+Candy/@50.9383585,6.9565409,17z/data=!3m1!4b1!4m5!3m4!1s0x47bf25a5ad663ad9:0x6cb65cbc7e7afa9d!8m2!3d50.9383585!4d6.9565409",
            icon: mapMarker,
            table: [
                {
                    open: "Maandag - Zaterdag",
                    time: "10:00—21:00",
                },
                {
                    open: "Zondag",
                    time: "geschlossen",
                },
            ]
        },
    ]
}

export {
    locations
};
