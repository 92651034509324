import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { CommingSoonText } from '../../assets/dummyData/popupNewsletter';
import { validateEmail } from '../../assets/function';
import ModalEmailSuccess from './ModalEmailSuccess';
import { axiosLink } from '../../axiosLink';
import LoaderMail from '../LoaderMail/LoaderMail';
import { Context } from '../../context/Products';

const CommingModal = ({ open, data, closeModalSoon }) => {
    const [{ lang }] = useContext(Context);
    const [showModalEmailSuccess, setShowModalEmailSuccess] = useState(true);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState();
    const [hasErrorMail, setHasErrorMail] = useState(false);
    const [disabledSend, setDisabledSend] = useState(true);
    const [agreement, setAgreement] = useState(false);
    const [required, setRequired] = useState(false);
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        message: "",
    });
    const body = document.body;

    const closeModal = (event) => {
        if (event.target.id === "modal-popup-newsletter" || event.target.id === "modal-popup-newsletter-span") {
            body.classList.remove("modal-open");
            setLoading(true);
            closeModalSoon(false)
        }
    }
    const onchange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      if (hasError) {
        setHasError(false);
      }
  }

    const validInputs = (e) => {
      if (e === "email") {
          const valid = validateEmail(formData.email);
          if (valid) {
              return false;
          } else {
              return true;
          }
      } else if (e === "phone") {
         return false
      } else {
          if (formData[e]?.length > 2) {
              return false;
          } else {
              return true;
          }
      }
    }
    const sendEmail = (event) => {
        event.preventDefault();
        const valid = validateEmail(formData?.email);
        if (valid) {
            setHasError(false);
            setRequired(false);
            setDisabledSend(false);
            const obj = {
                ...formData,
                state: "at"
            }
            axios.post(`${axiosLink}/mail/comming`, obj).then((data) => {
                setShowModalEmailSuccess(false);
                setLoading(true);
                setDisabledSend(true);
            }).catch((err) => {
                setHasErrorMail(true);
                setDisabledSend(true);
                setTimeout(() => {
                    setHasErrorMail(false);
                }, 4000);
            })
        } else {
            setRequired(true)
            setHasError(true);
        }
    }
    
    useEffect(() => {
        if (open) {
            body.classList.add("modal-open");
            setLoading(false);
            setAgreement(false);
            setFormData({
              fullName: "",
              email: "",
              message: "",
            })
        } else {
          body.classList.remove("modal-open");
        }
    }, [open])
    return (
        showModalEmailSuccess ? (
            <div id="modal-popup-newsletter" className="modal in fade fade-onload" role="dialog" style={loading ? { display: "none" } : { display: "block" }} onClick={(e) => closeModal(e)}>
                <div className="modal-dialog">
                    {/* <!-- Modal content--> */}
                    <div className="modal-content on-click">
                        <span className="close" id="modal-popup-newsletter-span" data-dismiss="modal" onClick={(e) => closeModal(e)}></span>
                        <div className="modal-body">
                            <div className="content">
                                <div className="inner">
                                    <span className="title-modal">{CommingSoonText[lang].title}</span>
                                    <div className="component-newsletter">
                                    <form id="contact_form" onSubmit={sendEmail} className="validate aos-init aos-animate">
                                        {data?.inputs?.map((element) => (
                                            <div className={`form-group  has-feedback ${(required && (validInputs(element.name))) && "has-error has-danger"}`} key={element.id}>
                                                <label  className="textarea" htmlFor={element.id}>{element.label}</label>
                                                <div className="berlicka">
                                                    {element?.textarea ? (
                                                            <textarea name={element.name} id={element.id} onChange={onchange} className="form-control" rows="4"></textarea>
                                                        )
                                                        : (
                                                            <input name={element.name} type="text" onChange={onchange} className={`${element?.class} form-control`} id={element.id} placeholder="" required="" />
                                                        )}
                                                    <span className="valid-icon"></span>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="clearfix"></div>
                                            <div className={`form-group agree ${(required && !agreement) && "has-error has-danger"}`}>
                                                <label></label>
                                                <div className="checkbox-wrap">
                                                    <input type="checkbox" id="checkbox" checked={agreement} name="agreement"  onChange={() => setAgreement(!agreement)} />
                                                    <label htmlFor="checkbox" className="circle"></label>
                                                    <label htmlFor="checkbox" className="label">{data?.link}</label>
                                                </div>
                                            </div>
                                            <button type="submit" className={`btn btn-primary ${(!agreement || !disabledSend) && "disabled"}`} disabled={!agreement}>{disabledSend ? data?.button : <LoaderMail /> }</button>
                                        </form>
                                        {/* <!-- IF error, ELSE open #modal-email-success bud pres JS(https://www.w3schools.com/bootstrap/bootstrap_ref_js_modal.asp) nebo pres btn viz nahore --> */}
                                        <span className={`error text-red ${!hasError && "hidden"}`}>
                                            {CommingSoonText[lang].error}  
                                        </span>
                                        <span className={`error text-red ${!hasErrorMail && "hidden"}`}>
                                            {CommingSoonText[lang].hasError}  
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        : (
            <ModalEmailSuccess open setOpenModalComming={() => {
                closeModalSoon();
                setShowModalEmailSuccess(true);
            }} />
        )
    )
};

export default CommingModal;