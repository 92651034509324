import React, { useContext } from 'react';
import Stores from '../components/Stores/Stores';
import { Context } from '../context/Products';
import { StorsData, cities } from "../assets/dummyData/StorsData";

const StoresPage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <Stores data={StorsData[lang]} cities={cities[lang]} />
  )
}

export default StoresPage;