import React, { useContext, useState } from 'react';
import AnimationEffects from '../assets/Animations/hooks/AnimationEffects';
import { NewsLetterContent } from '../assets/dummyData/NewsLetterData';
import { productsItems } from '../assets/dummyData/ProductsPageData';
import Helmet from '../components/Helmet';
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';
import NewLetter from '../components/NewLetter/NewLetter';
import Products from '../components/Products/Products';
import { Context } from '../context/Products';

const ProductsPage = () => {
    const [{ lang }] = useContext(Context);
    const { singleRefs } = AnimationEffects(['NewLetter'], [] ,'fadeInOnce', 0.4, 0.12);

    const [shoeModalEmail, setShoeModalEmail] = useState(false)

    const modalEmail = () => {
        setShoeModalEmail(true);
        const body = document.body;
        body.classList.add("modal-open");
        setTimeout(() => {
            setShoeModalEmail(false);
        }, 500);
    }

    return (
        <>  
            <Helmet
                title="Produkte | Kapitän Candy"
                metaDesc="Es gibt Tausende von Aromen auf der Welt. Wählen Sie bei uns Ihren Favoriten. Wir tun alles, was wir
                kann Ihnen Bonbons in bester Qualität von den besten Lieferanten anbieten, um auch die anspruchsvollsten zufrieden zu stellen
                Naschkatze."
            />
            <div id="page-products" className="page fade-onload">
                <Products
                    productsItems={productsItems[lang]}
                    title={productsItems.headTitle[lang]}
                    subTitle={productsItems.headSubTitle[lang]}
                    stores={productsItems.stores[lang]}
                    />
                <div className="opacity-0" ref={singleRefs.elements.NewLetter.ref}>
                    <NewLetter data={NewsLetterContent[lang]} openModal={modalEmail} />
                </div>
            </div>
            <ModalEmailSuccess open={shoeModalEmail} />
        </>
    )
}

export default ProductsPage;
