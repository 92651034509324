import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    const body = document.body;
    body.classList.remove("modal-open");
};

const handleScroll = () => {
    const section = document.getElementById( 'kontakt' );
    section.scrollIntoView( { behavior: 'smooth'} );
};

const contactData = {
    en: {
        title: "Contact us",
        history: <Fragment>
            Do you have something on your mind? Would you like to find something out from us? Don’t be afraid to write to us! <br />You can write us a scroll and send it to  <a href="mailto:info@captaincandy.at">info@captaincandy.at</a>, or fill in an entry in  <button className="button-underline" onClick={() => handleScroll()}>our logbook bellow</button>.
            </Fragment>,
        historyLink: <Fragment>If you’re interested in getting a franchise, <Link to="/franchising">go here</Link> to find out more.
        </Fragment>,
        questionTitle: "Send a question or request",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "What is it?",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send message"
    },
    nl: {
        title: "Neem contact met ons op",
        history: <Fragment>
            Heb je iets aan je hoofd? Wil je iets van ons weten? Wees niet bang om ons te schrijven!
            <br />
            U kunt ons een boekrol schrijven en opsturen naar <a href="mailto:info@captaincandy.at"> info@captaincandy.at</a>, of vul een invoer in <button className="button-underline" onClick={() => handleScroll('kontakt')}>ons logboek hieronder</button>.
            </Fragment>,
        historyLink: <Fragment>Als u geïnteresseerd bent in het krijgen van een franchise, <Link to="/franchising">ga hier</Link> om meer te weten te komen.
        </Fragment>,
        questionTitle: "Stuur een vraag of verzoek",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Naam en achternaam",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefoon nummer",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Wat is het?",
                textarea: true,
            },
        ],
        link: <Fragment>“Ik stem in met de <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">verwerking van mijn persoonsgegevens</Link> en de antwoorden op de vragenlijst.</Fragment>,
        button: "Bericht versturen"
    },
    de: {
        title: "Kontaktiere uns",
        history: <Fragment>
            Haben Sie etwas auf dem Herzen? Sie möchten etwas von uns erfahren? Scheuen Sie sich nicht, uns zu schreiben! <br />Sie können uns eine Schriftrolle schreiben und an <a href="mailto:info@captaincandy.at">info@captaincandy.at</a> senden oder einen Eintrag in <button className="button-underline" onClick={() => handleScroll()}>unten unser Logbuch</button>.
            </Fragment>,
        historyLink: <Fragment>Wenn Sie an einer Franchise interessiert sind, <Link to="/franchising">gehen Sie hier</Link>, um mehr zu erfahren.</Fragment>,
        questionTitle: "Senden Sie eine Frage oder Anfrage",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name und Nachname*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefon*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Warum möchten Sie mit Captain Candy zusammenarbeiten?",
                textarea: true,
            },
        ],
        link: <Fragment>“Ich stimme den Bedingungen der <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">Verarbeitung personenbezogener Daten</Link> und den Antworten im Fragebogen zu.“</Fragment>,
        button: "ABSENDEN"
    },
};

const contactDataCommingSoon = {
    en: {
        title: "Captain Candy is Looking for New Location in Austria!",
        history: <Fragment>
                Captain Candy, your favorite brand for delicious and unique sweets, is seeking new spaces to open new stores in the Austrian market!
            </Fragment>,
        listData: <Fragment>
         <h3>
            Why Captain Candy?
          </h3>
          <ul>
            <li>
              A Unique Experience: We offer a wide range of sweets you won’t find anywhere else.
            </li>
            <li>
              High Quality: Our products are made with selected ingredients and love.
            </li>
            <li>
              Magical Atmosphere: Our stores are designed to create an unforgettable experience for our customers.
            </li>
          </ul>
          <h3>
            We are Looking for location in:
          </h3>
          <ul>
            <li>
              Shopping Centers
            </li>
            <li>
              Main Streets
            </li>
            <li>
              High Traffic Areas
            </li>
          </ul>
        </Fragment>,
        questionTitle: "If you have a space that you think would be perfect for Captain Candy",
        contact: "contact us!",
        end: "Be part of the magical growth of Captain Candy and bring new sweetness to the hearts of Austrians!",
        inputs: [
            {
                id: "fullName",
                name: "fullName",
                label: "Name and surname",
            },
            {
                id: "email",
                name: "email",
                label: "E-mail",
            },
            {
                id: "phone",
                name: "phone",
                label: "Telephone number",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "message",
                name: "message",
                label: "Message",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send message"
    },
    de: {
        title: "Captain Candy sucht neuen Standort in Österreich!",
        history: <Fragment>
                Captain Candy, Ihre Lieblingsmarke für köstliche und einzigartige Süßigkeiten, sucht neue Räume, um neue Geschäfte auf dem österreichischen Markt zu eröffnen!
            </Fragment>,
        listData: <Fragment>
             <h3>Warum Captain Candy?</h3>
            <ul>
                <li>
                    Ein Einzigartiges Erlebnis: Wir bieten eine breite Palette an Süßigkeiten, die Sie sonst nirgends finden.
                </li>
                <li>
                    Hohe Qualität: Unsere Produkte werden mit ausgewählten Zutaten und Liebe hergestellt.
                </li>    
                <li>
                    Magische Atmosphäre: Unsere Geschäfte sind so gestaltet, dass sie unseren Kunden ein unvergessliches Erlebnis bieten.  
                </li>
              </ul>
              <h3>Wir suchen Räume in:</h3>
              <ul>
                <li>Einkaufszentren</li>
                <li>Hauptstraßen</li>
                <li>Hochfrequentierte Bereiche</li>
              </ul>
            </Fragment>,
        questionTitle: "Wenn Sie einen Raum haben, der Ihrer Meinung nach perfekt für Captain Candy wäre",
        contact: "kontaktieren Sie uns!",
        end: "Werden Sie Teil des magischen Wachstums von Captain Candy und bringen Sie neue Süße in die Herzen der Österreicher!",
        inputs: [
            {
                id: "fullName",
                name: "fullName",
                label: "Name und Nachname*",
            },
            {
                id: "email",
                name: "email",
                label: "E-mail",
            },
            {
                id: "phone",
                name: "phone",
                label: "Telefon*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "message",
                name: "message",
                label: "Nachricht",
                textarea: true,
            },
        ],
        link: <Fragment>“Ich stimme den Bedingungen der <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">Verarbeitung personenbezogener Daten</Link> und den Antworten im Fragebogen zu.“</Fragment>,
        button: "ABSENDEN"
    },
};

export {
    contactData,
    contactDataCommingSoon,
}