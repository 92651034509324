const emailSuccessData = {
    en: {
        title: "Confirm your e-mail – it’s torture-free we promise.",
        subttl: "We will not torture you, but if you don't confirm your login in the e-mail we just sent to you, you are forbidden to join the royal fleet ...",
        subttl1: "You’re just one step away – so get over to that inbox and confirm the message.",
        subttl2: "Thanks and here’s to a plain sailing voyage together."
    },
    de: {
        title: "Bestätigen Sie Ihre E-Mail – es ist folterfrei, versprochen.",
        subttl: "Wir werden Sie nicht foltern, aber wenn Sie Ihre Anmeldung in der E-Mail, die wir Ihnen gerade gesendet haben, nicht bestätigen, ist es Ihnen verboten, sich der königlichen Flotte anzuschließen ...",
        subttl1: "Sie sind nur einen Schritt davon entfernt – gehen Sie also zu diesem Posteingang und bestätigen Sie die Nachricht.",
        subttl2: "Danke und auf eine gemeinsame Segelreise."
    },
};

const emailConfirmData = {
    en: {
        title: "We have it!",
        subttl: "Your email has been successfully stored in our hold and is coming with us on our expeditions.",
        subttl1: "Just so you know - we've got a shipwrecked scribe on board, so he'll write you a scroll as soon as something new happens.",
        subttl2: "Look forward!",
        buttonName: "Continue",
    },
    de: {
        title: "Wir haben es!",
        subttl: "Ihre E-Mail wurde erfolgreich in unserem Frachtraum gespeichert und begleitet uns auf unseren Expeditionen.",
        subttl1: "Nur damit ihr es wisst – wir haben einen schiffbrüchigen Schreiber an Bord, also wird er euch eine Schriftrolle schreiben, sobald etwas Neues passiert.",
        subttl2: "Freuen!",
        buttonName: "Weitermachen",
    },
};

const emailSuccessCommingSoon = {
    en: {
        title: "Thank you for reaching out regarding the Captain Candy location scout information form. We appreciate your interest and swift response.",
        subttl: "We will review the information provided and get back to you as soon as possible. Should you have any further questions or require additional details, please don't hesitate to contact us.",
        subttl2: "Thanks and here’s to a plain sailing voyage together."
    },
    de: {
        title: "Vielen Dank, dass Sie uns bezüglich des Captain Candy Location Scout-Informationsformulars kontaktiert haben. Wir freuen uns über Ihr Interesse und Ihre schnelle Antwort.",
        subttl: "Wir prüfen die Angaben und melden uns schnellstmöglich bei Ihnen. Sollten Sie noch Fragen haben oder weitere Informationen benötigen, können Sie sich jederzeit gerne an uns wenden.",
        subttl2: "Danke und auf eine gemeinsame Segelreise."
    },
};

export {
    emailSuccessData, emailConfirmData, emailSuccessCommingSoon
};