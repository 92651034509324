import React, { useContext } from 'react';
import { sendMailData } from '../../assets/dummyData/SentEmail';
import { Context } from '../../context/Products';

export const SentEmail = ({ sendAnotherEmail }) => {
  const [{ lang }] = useContext(Context);
  return (
    <div className="msg-send" id="contact_form_send_success">
        <span className="ttl">
            {sendMailData[lang].title}
        </span>
        <p>
            {sendMailData[lang].subTitle}
        </p>
        <p>
            {sendMailData[lang].desc}
        </p>
        <button type="button" onClick={() => sendAnotherEmail(true)} className="btn btn-primary">{sendMailData[lang].button}</button>
    </div>
  )
}
