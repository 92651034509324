import React from 'react';
import { useContext } from 'react';
import { career } from '../assets/dummyData/career';
import Career from '../components/Career/Career';
import { Context } from '../context/Products';

const CareerPage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <Career data={career[lang]}/>
  )
}

export default CareerPage;