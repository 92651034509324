import { Fragment } from "react";
import firsPage from "../img/captain/jak_kapitan.png";
import firsPageNl from "../img/captain/jak_kapitan_nl.png";
import firsPageDe from "../imgde/page1.svg";
import also from "../imgde/also.svg";
import firsPageLod from "../img/captain/lod.png";
import hledaniVecneho from "../img/captain/hledani_vecneho.png";
import hledaniVecnehoNl from "../img/captain/hledani_vecneho_nl.png";
import pergamen from "../img/captain/pergamen.png";
import mladi from "../img/captain/mladi.png";
import mladiDe from "../imgde/img3.svg";
import final from "../imgde/final.svg";
import mladiNL from "../img/captain/mladi_nl.png";
import vaha from "../img/captain/vaha.png";
import mapa from "../img/captain/mapa.png";
import obchodnik from "../img/captain/obchodnik.png";
import najdete_i_vy_svuj_pokladNl from "../img/captain/najdete_i_vy_svuj_poklad_nl.png";
import najdete_i_vy_svuj_poklad from "../img/captain/najdete_i_vy_svuj_poklad.png";
import najdete_i_vy_svuj_pokladHover from "../img/captain/najdete_i_vy_svuj_poklad-hover.png";


const CaptainStoryData = {
    en: [
        {
            data:  <Fragment>
                        <div className="item one w-100 h-100">
                            <img src={firsPage} alt="How the Captain found his true treasure" />
                        </div>
                    </Fragment>,
        },
        {
            data:  <Fragment>
                        <div className="item two w-100 h-100">
                            <img src={firsPageLod} alt="loď" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    Nothing in the world could scare him. Nothing could deter him from his looting and plundering.
                                </p>
                                <img src={firsPageLod} alt="loď" className="visible-xs" />
                                <p>
                                    There was only thing that the Captain was afraid of. He was afraid of getting old.  And he was afraid that his famous pieces would one day be forgotten.
                                </p>
                            </div>
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item three w-100 h-100">
                            <img src={hledaniVecneho} alt="So he became.." className="hidden-xs" />
                            <img src={hledaniVecneho} alt="So he became.." className="visible-xs" style={{ marginTop: "20px !important" }} />
                            <img src={pergamen} alt="pergamen" className="hidden-xs imgCustomStyle" style={{ marginTop: "-2.5vw !important", width: "70% !important",  marginLeft: "15% !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item four w-100 h-100">
                            <img src={mladi} alt="..obsessed with the search for eternal youth." className="hidden-xs" />
                            <img src={mladi} alt="..obsessed with the search for eternal youth." className="visible-xs m-t--40" />
                            <img src={pergamen} alt="parchments" className="visible-xs no-margin" />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item five w-100 h-100">
                            <img src={vaha} alt="weighing-machine" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    He would appear as a spectre
                                </p>
                                <p>
                                    He would pillage, kidnap, interrogate…
                                </p>
                                <p>
                                    He wasn’t looking for treasure like everyone else. He wasn’t interested in gold, he wasn’t interested in precious metals, he wasn’t interested in diamonds.
                                </p>
                                <p>
                                He was looking for the elixir of eternal youth.
                                </p>
                            </div>
                            <img src={vaha} alt="weighing-machine" className="visible-xs" style={{ marginBottom: "15px !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item six w-100 h-100">
                            <div className="text">
                                <p>
                                    In an effort to save their lives, his prisoners sent him to islands that were not shown on nautical charts, to priestesses about whom only the very darkest legends were told, to look for plants shown only on the oldest parchments. But none of this advice concealed the recipe for eternal youth.
                                </p>
                            </div>
                            <img src={mapa} alt="map" className="hidden-xs" />
                            <img src={mapa} alt="map" className="visible-xs" style={{ marginTop: "40px !important", marginBottom: "25px !important" }} />
                        </div>
                    </Fragment>
        },
        {
            data:   <Fragment>
                        <div className="item seven w-100 h-100">
                            <div className="text">
                                <p>
                                It was only in one of the towns he travelled to that an old man, a merchant, fell into the hands of the Captain. Unlike the others he didn’t think up some fairy story. Instead, he took the Captain to his home and offered him a small, round, shiny sweet.
                                </p>
                            </div>
                            <img src={obchodnik} alt="Trader" className="hidden-xs" />
                            <img src={obchodnik} alt="Trader" className="visible-xs" style={{ marginTop: "45px !important", marginBottom: "20px !important" }} />
                        </div>
                    </Fragment>
        },
        {
            data:   <Fragment>
                        <div className="item eight w-100 h-100">
                            <div className="text">
                                <p>
                                    The taste of this tiny morsel took the Captain back to his childhood. And he understood. <br /> You can’t stay young forever.<br /> But anyone can go back to their youth for a little while!
                                </p>
                            </div>
                            <div className="img-wrap">
                                <a href="/products" className="relative">
                                    <img src={najdete_i_vy_svuj_poklad} alt="Find your treasure too!" />
                                    <img src={najdete_i_vy_svuj_pokladHover} className="hover" alt="Find your treasure too!" />
                                </a>
                            </div>
                        </div>
                    </Fragment>
        },
    ],
    nl: [
        {
            data:  <Fragment>
                        <div className="item one w-100 h-100">
                            <img src={firsPageNl} alt="Hoe de kapitein zijn echte schat vond" />
                        </div>
                    </Fragment>,
        },
        {
            data:  <Fragment>
                        <div className="item two w-100 h-100">
                            <img src={firsPageLod} alt="loď" className="hidden-xs" />
                            <div className="text">
                                <p>
                                Niets of niemand kon hem laten beven. Niets kon hem weerhouden van een leven vol roven en plunderen. Er was maar een ding waar hij bang voor was. Hij was bang om oud te worden.
                                </p>
                                <img src={firsPageLod} alt="loď" className="visible-xs" />
                                <p>
                                    En hij was bang dat zijn beruchte daden op een dag in de vergetelheid zouden raken.
                                </p>
                            </div>
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item three w-100 h-100">
                            <img src={hledaniVecnehoNl} alt="So he became.." className="hidden-xs" />
                            <img src={hledaniVecnehoNl} alt="So he became.." className="visible-xs" style={{ marginTop: "20px !important" }} />
                            <img src={pergamen} alt="pergamen" className="hidden-xs imgCustomStyle" style={{ marginTop: "-2.5vw !important", width: "70% !important",  marginLeft: "15% !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item four w-100 h-100">
                            <img src={mladiNL} alt="..Met de zoektocht naar de eeuwige jeugd." className="hidden-xs" />
                            <img src={mladiNL} alt="..Met de zoektocht naar de eeuwige jeugd." className="visible-xs m-t--40" />
                            <img src={pergamen} alt="parchments" className="visible-xs no-margin" />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item five w-100 h-100">
                            <img src={vaha} alt="weighing-machine" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    Hij zou verschijnen als spookgedaante
                                </p>
                                <p>
                                    Hij plunderde, kidnapte en ondervroeg...
                                </p>
                                <p>
                                    Hij zocht niet naar schatten zoals alle anderen. Goud en edelmetalen interesseerden hem niet, diamanten konden hem gestolen worden.
                                    <br />
                                    Hij was op zoek naar het elixer van de eeuwige jeugd.
                                </p>
                            </div>
                            <img src={vaha} alt="weighing-machine" className="visible-xs" style={{ marginBottom: "15px !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                    <div className="item six w-100 h-100">
                        <div className="text">
                            <p>
                                In een poging hun levens te redden, stuurden zijn gevangenen hem naar eilanden die niet op
                                nautische kaarten stonden, naar priesteressen waarover duistere legendes werden verteld, en om
                                planten te zoeken die alleen werden afgebeeld op oude stukken perkament. Maar geen van de
                                adviezen onthulden het geheim van de eeuwige jeugd.
                            </p>
                        </div>
                        <img src={mapa} alt="map" className="hidden-xs" />
                        <img src={mapa} alt="map" className="visible-xs" style={{ marginTop: "40px !important", marginBottom: "25px !important" }} />
                    </div>
                </Fragment>
        },
        {
            data:   <Fragment>
                    <div className="item seven w-100 h-100">
                        <div className="text">
                            <p>
                                Het was pas in een van de steden die hij tijdens zijn reizen bezocht dat een oude man, een koopman,
                                in handen viel van de kapitein. In tegenstelling tot de anderen verzon hij geen fabeltje. In plaats
                                daarvan nam hij de kapitein mee naar zijn huis en bood hem een klein, rond, glanzend snoepje aan.
                            </p>
                        </div>
                        <img src={obchodnik} alt="Trader" className="hidden-xs" />
                        <img src={obchodnik} alt="Trader" className="visible-xs" style={{ marginTop: "45px !important", marginBottom: "20px !important" }} />
                    </div>
                </Fragment>
        },
        {
            data:   <Fragment>
                    <div className="item eight w-100 h-100">
                        <div className="text">
                            <p>
                                De smaak van dit kleine stukje snoepgoed bracht de kapitein terug naar zijn kindertijd. En hij begreep
                                het.
                                <br />
                                Je kunt niet altijd jong blijven.
                                <br />
                                Maar je kunt wel eventjes terug gaan naar je jeugd!
                            </p>
                        </div>
                        <div className="img-wrap">
                            <a href="/products" className="relative">
                                <img src={najdete_i_vy_svuj_pokladNl} alt="Find your treasure too!" />
                                <img src={najdete_i_vy_svuj_pokladHover} className="hover" alt="Find your treasure too!" />
                            </a>
                        </div>
                    </div>
                </Fragment>
        },
    ],
    de: [
        {
            data:  <Fragment>
                        <div className="item one w-100 h-100">
                            <img src={firsPageDe} alt="Wie der Kapitän seinen wahren Schatz fand" />
                        </div>
                    </Fragment>,
        },
        {
            data:  <Fragment>
                        <div className="item two w-100 h-100">
                            <img src={firsPageLod} alt="loď" className="hidden-xs" />
                            <div className="text">
                                <p>
                                Nichts auf der Welt konnte ihm Angst machen. Nichts konnte ihn von seinen Raubzügen und Plünderungen abhalten.
                                </p>
                                <img src={firsPageLod} alt="loď" className="visible-xs" />
                                <p>
                                    Es gab nur etwas, wovor der Kapitän Angst hatte. Er hatte Angst, alt zu werden. Und er hatte Angst, dass seine berühmten Aktionen eines Tages in Vergessenheit geraten würden.   
                                </p>
                            </div>
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item three w-100 h-100">
                            <img src={also} alt="Also wurde er besessen" className="hidden-xs" />
                            <img src={also} alt="Also wurde er besessen" className="visible-xs" style={{ marginTop: "20px !important" }} />
                            <img src={pergamen} alt="pergamen" className="hidden-xs imgCustomStyle" style={{ marginTop: "-2.5vw !important", width: "70% !important",  marginLeft: "15% !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item four w-100 h-100">
                            <img src={mladiDe} alt="Auf der Suche nach ewiger Jugend." className="hidden-xs" />
                            <img src={mladiDe} alt="Auf der Suche nach ewiger Jugend." className="visible-xs m-t--40" />
                            <img src={pergamen} alt="parchments" className="visible-xs no-margin" />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item five w-100 h-100">
                            <img src={vaha} alt="Er würde als Gespenst erscheinen" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    Er würde als Gespenst erscheinen 
                                    <br />
                                    Er würde plündern, entführen, verhören…
                                </p>
                                <p>
                                    Er suchte nicht nach Schätzen wie alle anderen. Er interessierte sich nicht für Gold, er interessierte sich nicht für Edelmetalle, er interessierte sich nicht für Diamanten.
                                </p>
                                <p>
                                    Er suchte nach dem Elixier der ewigen Jugend.
                                </p>
                            </div>
                            <img src={vaha} alt="weighing-machine" className="visible-xs" style={{ marginBottom: "15px !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item six w-100 h-100">
                            <div className="text">
                                <p>
                                    Um ihr Leben zu retten, schickten ihn seine Gefangenen auf Inseln, die nicht auf Seekarten verzeichnet waren, zu Priesterinnen, von denen nur die dunkelsten Legenden erzählt wurden, um nach Pflanzen zu suchen, die nur auf den ältesten Pergamenten abgebildet waren. Aber keiner dieser Ratschläge verbarg das Rezept für die ewige Jugend.   
                                </p>
                            </div>
                            <img src={mapa} alt="map" className="hidden-xs" />
                            <img src={mapa} alt="map" className="visible-xs" style={{ marginTop: "40px !important", marginBottom: "25px !important" }} />
                        </div>
                    </Fragment>
        },
        {
            data:   <Fragment>
                        <div className="item seven w-100 h-100">
                            <div className="text">
                                <p>
                                    Nur in einer der Städte, die er bereiste, fiel ein alter Mann, ein Kaufmann, in die Hände des Kapitäns. Im Gegensatz zu den anderen hat er sich kein Märchen ausgedacht. Stattdessen nahm er den Kapitän mit zu sich nach Hause und bot ihm eine kleine, runde, glänzende Süßigkeit an.    
                                </p>
                            </div>
                            <img src={obchodnik} alt="Trader" className="hidden-xs" />
                            <img src={obchodnik} alt="Trader" className="visible-xs" style={{ marginTop: "45px !important", marginBottom: "20px !important" }} />
                        </div>
                    </Fragment>
        },
        {
            data:   <Fragment>
                        <div className="item eight w-100 h-100">
                            <div className="text">
                                <p>
                                    Der Geschmack dieses kleinen Bissens versetzte den Kapitän in seine Kindheit zurück. Und er verstand.
                                    <br /> Du kannst nicht ewig jung bleiben.
                                    <br /> Aber jeder kann für eine Weile in seine Jugend zurückkehren!
                                </p>
                            </div>
                            <div className="img-wrap">
                                <a href="/products" className="relative">
                                    <img src={final} alt="Finden auch Sie Ihren Schatz!" />
                                    <img src={najdete_i_vy_svuj_pokladHover} className="hover" alt="Finden auch Sie Ihren Schatz!" />
                                </a>
                            </div>
                        </div>
                    </Fragment>
        },
    ],
};

export {
    CaptainStoryData,
};