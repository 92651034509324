import React, { useContext, useState, useEffect } from 'react';
import { emailSuccessData } from '../../assets/dummyData/emailStatus';
import { Context } from '../../context/Products';

const ModalEmailSuccess = ({ open }) => {
    const [{ lang }] = useContext(Context);
    const [openModal, setOpenModal] = useState(false);
    const body = document.body;

    const closeModal = (event) => {
        if (event.target.id === "modal-email-success" || event.target.id === "modal-email-success-span") {
            body.classList.remove("modal-open");
            setOpenModal(false)
        }
    }

    useEffect(() => {
        if (open) {
            setOpenModal(true);
        }
    }, [open]);

    return (
        <div id="modal-email-success" className="modal active in fade fade-onload" style={openModal ? { display: "block" } : { display: "none" }}  role="dialog" onClick={(e) => closeModal(e)}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <span className="close" id="modal-email-success-span" data-dismiss="modal" onClick={(e) => closeModal(e)}></span>
                    <div className="modal-body">
                        <div className="content">
                            <div className="inner">
                                <span className="ttl">{emailSuccessData[lang].title}</span>
                                <span className="subttl">{emailSuccessData[lang].subttl}</span>
                                <br />
                                <span className="subttl">{emailSuccessData[lang].subttl1}</span>
                                <div className="spacer60"></div>
                                <span className="subttl pull-right">
                                    {emailSuccessData[lang].subttl2}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalEmailSuccess;
