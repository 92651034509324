import { Fragment } from "react";
import img1 from "../img/products/1-xs-min.png"
import img2 from "../img/products/2-xs-min.png"
import img3 from "../img/products/3-xs-min.png"
import img4 from "../img/products/4-xs-min.png"
import img5 from "../img/products/5-xs-min.png"
import img6 from "../img/products/6-xs-min.png"

const productsItems = {
    headTitle: {
        en: <Fragment>Hundreds of shapes, even more flavours <br className="hidden-xs" /> There are thousands of flavours in the world. Everyone has the chance to discover their favourite!</Fragment>,
        nl: <Fragment>Honderden vormen en nog meer smaken <br className="hidden-xs" />  Er zijn duizenden smaken in de wereld. Iedereen krijgt de kans om hun favoriet te ontdekken!</Fragment>,
        de: <Fragment>Hunderte von Formen, noch mehr Geschmacksrichtungen <br className="hidden-xs" /> Es gibt Tausende von Geschmacksrichtungen auf der Welt. Jeder hat die Chance, seinen Favoriten zu entdecken!</Fragment>,
    },
    headSubTitle: {
        en: "We believe everyone deserves to discover sweets they’ll want to devour. This is why we choose the very best ingredients and the most reliable suppliers. Take a peek inside our barrels!",
        nl: "We zijn van mening dat iedereen de kans verdient om de snoepjes te ontdekken die ze willen versmaden. Dat is de reden dat we de beste ingrediënten kiezen van de meest betrouwbare leveranciers. Kijk eens in onze tonnen!",
        de: "Wir glauben, dass jeder es verdient, Süßigkeiten zu entdecken, die er verschlingen möchte. Deshalb wählen wir die besten Zutaten und die zuverlässigsten Lieferanten. Werfen Sie einen Blick in unsere Fässer!",
    },
    stores: {
        en: "Our stores",
        nl: "Onze winkels",
        de: "schäftsstellen",
    },
    en: [
        {
            title: "Tangy jelly",
            description: "Not every pirate will admit to having a sweet tooth. This seriously tangy jelly is different!  Treat yourself to your favourite sweet while holding onto your hard-core image with its rock hard outside and sweet soft centre.",
            img: img1,
        },
        {
            title: "Sweet jelly",
            description: "Banana, strawberry, bear or a dual-coloured skull, take your pick. This is the king among sweets. On a number of islands we visited, the wealth of the future bride is judged by the size of her jelly dowry!",
            img: img2,
        },
        {
            title: "Batons",
            description: "The baton forms an integral part of all pirate expeditions. Not only does it taste great and have fantastic lasting power, but if necessary it can also double up as a sailing rope and can come in handy for tying up hostages.",
            img: img3,
        },
        {
            title:<Fragment>Marsh-<br />mallow</Fragment>,
            description: "If only our ship was made out of marshmallow! It would either reflect or soak up any cannon balls thrown at us by our enemy. We’ve already tried to build a small sloop out of it, however most of the material mysteriously evaporated… ",
            img: img4,
        },
        {
            title: "Chocolate",
            description: "While every proper pirate is tough on the outside, but soft on the inside, you’ll find the exact opposite with these chocolate sweets. Beneath the softest chocolate shell we find crunchy almonds, hazelnuts, chunks of coconut and much more!",
            img: img5,
        },
        {
            title: "Chewing gum",
            description: "When not chewing coca leaves, pirates can be found chewing gum. But not the typical white variety. Even the lowest ranking sailor turns his nose up at that. Pirate chewing gum is colourful and tastes different every time!",
            img: img6,
        },
    ],
    de: [
        {
            title: "Würziges Gelee",
            description: "Nicht jeder Pirat wird zugeben, dass er eine Naschkatze ist. Dieses äußerst scharfe Gelee ist anders! Gönnen Sie sich Ihre Lieblingssüßigkeit, während Sie mit seiner steinharten Außenschale und der süßen weichen Mitte Ihr Hardcore-Image beibehalten.",
            img: img1,
        },
        {
            title: "Süßes Gelee",
            description: "Banane, Erdbeere, ein Bär oder ein zweifarbiger Totenkopf, wählen Sie aus. Dies ist der König unter den Süßigkeiten. Auf einer Reihe von Inseln, die wir besucht haben, wird der Reichtum der zukünftigen Braut nach der Größe ihrer Gelee-Mitgift beurteilt!",
            img: img2,
        },
        {
            title: "Stäbchen",
            description: "Die Stäbchen sind ein fester Bestandteil aller Piratenexpeditionen. Sie schmecken nicht nur hervorragend und haben eine fantastische Ausdauer, sondern können bei Bedarf auch als Segeltau dienen und zum Anbinden von Geiseln nützlich sein.",
            img: img3,
        },
        {
            title:<Fragment>Marsh-<br />mallows</Fragment>,
            description: "Wenn unser Schiff doch nur aus Marshmallows wäre! Es würde alle Kanonenkugeln, die unser Feind auf uns wirft, entweder reflektieren oder aufsaugen. Wir haben schon versucht, daraus eine kleine Schaluppe zu bauen, aber das meiste Material ist auf mysteriöse Weise verdampft…",
            img: img4,
        },
        {
            title: "Schokolade",
            description: "Während jeder richtige Pirat außen zäh, aber innen weich ist, finden Sie bei diesen Schokoladen-Süßigkeiten genau das Gegenteil. Unter der zartesten Schokoladenhülle finden sich knackige Mandeln, Haselnüsse, Kokosraspeln und vieles mehr!",
            img: img5,
        },
        {
            title: "Kaugummi",
            description: "Wenn sie keine Kokablätter kauen, können Piraten Kaugummi kauen. Aber nicht die typische weiße Sorte. Da rümpft selbst der rangniedrigste Seemann die Nase. Ein Kaugummi für Piraten ist bunt und schmeckt jedes Mal anders!",
            img: img6,
        },
    ],
}

export { productsItems };