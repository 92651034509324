import { Fragment } from "react";
import hpAboutImg from "../img/hp_about_1_inner-min.jpg";
import hpAboutInner from "../img/hp_about_2_inner-min.jpg";
import promoText from "../img/promo_text-sm_en.svg";
import promoTextXs from "../img/promo_text-xs_en.svg";
import promoTextXsNl from "../img/web/welcome-nl-xs.svg";
import promoTextNl from "../img/web/welcome-nl.svg";
import promoTextXsDe from "../imgde/willkommen-de.svg";
import promoTextDe from "../imgde/Willkommen.svg";

const homePageData = {
    en: [
        {
            id: "123",
            img: hpAboutImg,
            title: "Find your treasure",
            description: "Raise the anchor of your imagination and set sail with us to discover the taste of eternal youth. Each of our candies conceals a different story!",
            link: "/products#tangy-jelly",
            buttonName: <Fragment>Explore candy <span>islands</span></Fragment>,
        },
        {
            id: "124",
            img: hpAboutInner,
            title: "I, Captain Candy",
            description: <Fragment>Discover a story full of adventure,<br />suspense and knowledge</Fragment>,
            link: "/captain-story",
            buttonName: <Fragment>My story</Fragment>,
        },
    ],
    nl: [
        {
            id: "123",
            img: hpAboutImg,
            title: "Zoek je schat",
            description: "Licht het anker van je verbeelding en zet koers met ons om de smaken van eeuwige jeugd te ontdekken. Elk van onze snoepjes heeft een uniek verhaal!",
            link: "/products#tangy-jelly",
            buttonName: <Fragment>Verken de<span>snoepeilandjes</span></Fragment>,
        },
        {
            id: "124",
            img: hpAboutInner,
            title: "Ik, Captain Candy",
            description: <Fragment>Ontdek een verhaal vol avontuur, <br />spanning en kennis</Fragment>,
            link: "/captain-story",
            buttonName: <Fragment>Mijn verhaal</Fragment>,
        },
    ],
    de: [
        {
            id: "123",
            img: hpAboutImg,
            title: "Finde deinen Schatz",
            description: "Lichten Sie den Anker Ihrer Fantasie und setzen Sie mit uns die Segel, um den Geschmack der ewigen Jugend zu entdecken. Jede unserer Süßigkeiten verbirgt eine andere Geschichte!",
            link: "/products#tangy-jelly",
            buttonName: <Fragment>Entdecken Sie <span>Candy Islands!</span></Fragment>,
        },
        {
            id: "124",
            img: hpAboutInner,
            title: "Ich, Captain Candy",
            description: <Fragment>Entdecke eine Geschichte voller Abenteuer,<br /> Spannung und Wissen</Fragment>,
            link: "/captain-story",
            buttonName: <Fragment>Meine Geschichte</Fragment>,
        },
    ],
};

const promoTextHeader = {
    en: {
        text: "Welcome on varis board",
        promoText: promoText,
        promoTextXs: promoTextXs,
    },
    nl: {
        text: "Welkom op varisbord",
        promoText: promoTextNl,
        promoTextXs: promoTextXsNl,
    },
    de: {
        text: "Willkommen im Varis-Board",
        promoText: promoTextXsDe,
        promoTextXs: promoTextDe,
    }
};

export {
    homePageData,
    promoTextHeader,
};