import novinkyNaEmailEn from "../img/novinky-na-email_en.svg";
import FlessenpostOntvangen from "../imgde/Flaschenpost-Empfang.svg";
import nachricht from "../imgde/nachricht.svg";
import { Fragment } from "react";

const NewsLetterContent = {
    en: {
        img: novinkyNaEmailEn,
        title: <Fragment>Hopefully you don't see this as a threat, but if you don't confirm your registration, we'll be allowed to
        Royal Fleet not befriend you...<br />
        It's just one step – check your email box and confirm the message.<br />
        Thank you. We wish you lots of wind in the right direction.</Fragment>,
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
        emailExist: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
    },
    de: {
        img: FlessenpostOntvangen,
        title: "Bis vor einigen Jahren waren unsere Botschaften auf Pergament geätzt und in Flaschen verschickt worden. Zum Glück haben wir heutzutage E-Mails. Schreiben Sie Ihre Adresse auf das Blatt, um als Erster von allen Expeditionen und Schätzen des Kapitäns zu erfahren.",
        placeholder: "Geben sie ihre E-Mailadresse ein",
        buttonName: "Registrieren",
        error: <Fragment>Staub und Kugeln, jemand hat diese E-Mail bereits vor Ihnen eingegeben! <br />Geben Sie bitte eine andere E-Mail-Adresse ein.</Fragment>,
        emailExist: <Fragment>Staub und Kugeln, jemand hat diese E-Mail bereits vor Ihnen eingegeben! <br />Geben Sie bitte eine andere E-Mail-Adresse ein.</Fragment>,
    }
};
const NewsLetterContentFaq = {
    en: {
        img: novinkyNaEmailEn,
        title: "Up until a few years back, our messages would have been etched on parchment and sent out in bottles. Fortunately we have e-mail these days. Write your address on the sheet to be the first to find out about all the captain’s expeditions and treasures.",
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
        emailExist: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
    },
    de: {
        img: nachricht,
        title: <Fragment>
                Wir werden Sie nicht foltern, aber wenn Sie Ihr Login in der E-Mail, die wir Ihnen gerade geschickt haben, nicht bestätigen, ist es uns von der königlichen Flotte untersagt, sich mit Ihnen anzufreunden… <br />
                Nur einen Schritt entfernt – gehen Sie zu Ihrem E-Mail-Postfach und bestätigen Sie die Nachricht.<br />
                Vielen Dank und wir wünschen guten Wind in den Segeln.<br />

            </Fragment>,
        placeholder: "Bestätigen Sie Ihre E-Mail ohne Folter.",
        buttonName: "Gesendet",
        error: <Fragment>Staub und Kugeln, jemand hat diese E-Mail bereits vor Ihnen eingegeben! <br />Geben Sie bitte eine andere E-Mail-Adresse ein.</Fragment>,
        emailExist: <Fragment>Staub und Kugeln, jemand hat diese E-Mail bereits vor Ihnen eingegeben! <br />Geben Sie bitte eine andere E-Mail-Adresse ein.</Fragment>,
    }
};

export {
    NewsLetterContent,
    NewsLetterContentFaq,
};