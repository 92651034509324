import React, { useContext, useState } from 'react'
import { Context } from '../../context/Products';
import { contactDataCommingSoon } from '../../assets/dummyData/contactData';
import "./style.scss";
// import bg from "../../assets/imgat/bg.png";
import skull from "../../assets/imgat/skull.png";
// import map from "../../assets/imgat/map.webp";
import map from "../../assets/imgat/Europe.jpeg";
import CommingModal from './CommingModal';
import { Link } from 'react-router-dom';

const CommingSoon = ({ title }) => {
  const [openModal, setOpenModal] = useState(false)
  const [{ lang }] = useContext(Context);
  return (
    <section className="CommingSoon">
      <div className="container-custom page page-plain fade-onload page-faq">
        <h2 className="section-heading">
          {contactDataCommingSoon[lang].title}
        </h2>
        <div className="parent">
          <div className="child"> <div className="content-soon col-6">
            {/* <img src={bg} className='comming-img' alt='captain candy' /> */}
              <h3>{contactDataCommingSoon[lang].history}</h3>
              <img src={skull} className='skull' alt='captain candy' />
             {contactDataCommingSoon[lang].listData}
              <p>
                {contactDataCommingSoon[lang].questionTitle}, <Link onClick={() => setOpenModal(true)}>{contactDataCommingSoon[lang].contact}</Link> 
              </p>
              <p>
              {contactDataCommingSoon[lang].end}
              </p>
            </div>
          </div>
          <div className="child">
            <div className="content-soon-left">
              <img src={map} alt="Captain Candy" />
            </div>
          </div>
        </div>
      </div>
      <CommingModal open={openModal} data={contactDataCommingSoon[lang]} closeModalSoon={() => setOpenModal(false)} />
    </section>
  )
}

export default CommingSoon;