import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const franchisingData = {
    en: {
        title: "Captain Candy Franchising",
        history: [
            {
                question: "Captain Candy concept caught your eye?",
                answer: "Captain Candy is a successful and proven concept operating a network of candy shops. Our shops are so unique that in many cases they have been the subject of illegal copying. However, there is only one original Captain Candy. In our stores, we do not only focus on a wide selection of the most delicious candies, but the overall customer experience is just as important to us.",
            },
            {
                question: "Keen to spread the joy of returning to being a kid to others around you?",
                answer: <Fragment>With a Captain Candy franchise, you can offer your customers the joy of discovery and the excitement of returning to their childhood. <br />
                For more information, please fill out the questionnaire below.</Fragment>
            },
        ],
        list: [
            {
                headingtitle: "Why work with us as a franchising partner?",
                headingLi: [
                    'Our concept boasts its own unique style',
                    "We offer quality of an international calibre",
                    "We’re constantly developing and improving",
                    "We’re a tried and tested European brand",
                ],
            },
            {
                headingtitle: "What we offer franchising partners?",
                headingLi: [
                    "The security of working for an international brand",
                    "Tried and tested sales know-how, which is constantly being innovated",
                    "Well-thought out, tried and tested and ready-made solutions",
                    "Help not only before opening your store and in the early stages, but also any time further down the line",
                    "The opportunity for personal development and self-realisation in getting your business off the ground",
                    "The chance to contribute to the development and growth of the brand",
                    "Stocking up with products and the necessary equipment to operate a franchise",
                    "System of regular checks and consultations",
                    "Long-term income and appreciation of your investment",
                    "Mutual agreement on a specific form of cooperation",
                ]
            }
        ],
        listParagraphsTitle: "How to get a Captain Candy franchise",
        listParagraphs:  [
            "We see franchising as a longstanding and mutually beneficial relationship, a bit like accepting another person into our business family. We therefore take great care to ensure our selection process is fair, thorough and prudent.",
            " For this reason anyone interested in applying is asked to complete a QUESTIONNAIRE to share preliminary (and non-binding) information with us, which allows us to ascertain whether our future collaboration is built on sufficiently solid foundations and has real prospects for success. We’ll get in touch after reading through your responses.",
            <Fragment><i> By sending your responses, you are automatically entered onto our database of people interested in working with us. We treat any information contained in the questionnaire in accordance with the General Data Protection Regulation (GDPR) and use it exclusively for the purpose of potential collaboration with you.</i></Fragment>,
        ],
        questionTitle: "QUESTIONNAIRE",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail*",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text_1",
                name: "contactform_text_1",
                label: "Why do you want to work with Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_2",
                name: "contactform_text_2",
                label: "How did you find out about Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_3",
                name: "contactform_text_3",
                label: "What is your current professional experience?*",
                textarea: true,
            },
            {
                id: "file_upload",
                name: "file_upload",
                label: "Please attach a detailed CV*",
                button: "Browse"
            },
            {
                id: "contactform_text_4",
                name: "contactform_text_4",
                label: "Do you have any experience running a small business? If o what?*",
                textarea: true,
            },
            {
                id: "contactform_text_5",
                name: "contactform_text_5",
                label: "Do you work for any other brands?*",
                textarea: true,
            },
            {
                id: "contactform_text_6",
                name: "contactform_text_6",
                label: "Do you have any other commitments or things that would restrict you from this work? Have you signed a competition clause preventing you from working with a particular brand or concept?*",
                textarea: true,
            },
            {
                id: "contactform_text_7",
                name: "contactform_text_7",
                label: "Do you or have you (or anyone close to you) previously run a franchise?",
                textarea: true,
            },
            {
                id: "contactform_text_8",
                name: "contactform_text_8",
                label: "If you’ve never run your own business before, what is your motivation for starting one now?",
                textarea: true,
            },
            {
                id: "contactform_text_9",
                name: "contactform_text_9",
                label: "Do you have a clean criminal record and are you prepared to supply proof of this?*",
                textarea: true,
            },
            {
                id: "contactform_text_10",
                name: "contactform_text_10",
                label: "To what degree can you take part in the day to day running of the store?*",
                textarea: true,
            },
            {
                id: "contactform_text_11",
                name: "contactform_text_11",
                label: "What makes you think that Captain Candy will be a success in your city, country or region?",
                textarea: true,
            },
            {
                id: "contactform_text_12",
                name: "contactform_text_12",
                label: "Where do you want to open your premises? What town, big city, region or country interest you in terms of running Captain Candy franchising stores?*",
                textarea: true,
            },
            {
                id: "contactform_text_13",
                name: "contactform_text_13",
                label: "Do you have your own/rented sales spaces to run your Captain Candy store? How big is it, what are the floor plans and where is it? How long will you have it for?",
                textarea: true,
            },
            {
                id: "contactform_text_14",
                name: "contactform_text_14",
                label: "What level of funds are you prepared to invest in the Captain Candy project? How do you plan to finance construction, furnishings and running costs during the start-up phase? How much can you source from your own funds?*",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the  <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send"
    },
    de: {
        title: "Captain Candy Franchising",
        history: [
            {
                question: "Interessieren Sie sich für das Konzept von Captain Candy?",
                answer: "Captain Candy ist ein erfolgreiches und bewährtes Konzept mit einem guten Netzwerk an Süßwarengeschäften. Unsere Läden sind so einzigartig, dass sie in vielen Fällen Gegenstand illegaler Kopien waren. Wie auch immer, es gibt nur einen originalen Captain Candy. In unseren Läden bieten wir nicht nur eine große Auswahl der köstlichsten Süßigkeiten, sondern das gesamte Kundenerlebnis ist uns wichtig.",
            },
            {
                question: "Möchten Sie die Entdeckerfreude und die Begeisterung für die Rückkehr zu Ihrer Jugend in Ihrem Umfeld teilen?",
                answer: <Fragment>Mit einem Captain Candy-Franchisegeschäft können Sie Ihren Kunden die Freude am Entdecken sowie den Charme ihrer Kindheit zurückgeben.
                        <br/>
                        Wenn Sie weitere Informationen wünschen, füllen Sie bitte den untenstehenden Fragebogen aus.
                    </Fragment>,
            },
        ],
        list: [
            {
                headingtitle: "Warum sollten Sie mit uns als Franchise-Partner*in zusammenarbeiten?",
                headingLi: [
                    "Unser Konzept hat seinen eigenen einzigartigen Stil",
                    "Wir bieten Qualität auf internationalem Niveau",
                    "Wir entwickeln und verbessern das Konzept ständig weiter",
                    "Wir sind eine bewährte europäische Marke",
                ],
            },
            {
                headingtitle: "Was bieten wir unseren Franchise-Partnern?",
                headingLi: [
                    "Die Sicherheit eines erfolgreichen Geschäfts unter einer internationalen Marke",
                    "Ein bewährtes Vertriebs-Know-how, das wir ständig weiterentwickeln",
                    "Erdachte, getestete, bewährte und schlüsselfertige Lösungen",
                    "Wir helfen Ihnen nicht nur vor der Eröffnung und am Anfang, sondern auch jederzeit danach",
                    "Die Gelegenheit zur persönlichen Entwicklung und Selbstverwirklichung bei der Eröffnung Ihrer Geschäftsstelle",
                    "Die Möglichkeit, an der Entwicklung und dem Wachstum der Marke teilzuhaben",
                    "Bereitstellung der Produkte und die Ausstattung, die für den Betrieb des Franchise-Unternehmens erforderlich sind",
                    "Ein System mit Überprüfungen und mit Rücksprache",
                    "Ein langfristiges Einkommen und eine Rendite für Ihre Investition",
                    "Gegenseitiges Einvernehmen über eine bestimmte Form der Zusammenarbeit",
                ]
            }
        ],
        listParagraphsTitle: "Wie kann ich ein Franchise-Unternehmen mit Captain Candy gründen?",
        listParagraphs:  [
            "Wir verstehen Franchising als eine langfristige und für beide Seiten nützliche Beziehung, so wie die Aufnahme eines weiteren Mitglieds in unsere Unternehmensfamilie. Deshalb gehen wir die Auswahl fair, sorgfältig und umsichtig an.",
            "Für jede Interessentin und jeden Interessenten, der oder die sich an uns wendet, starten wir zunächst einen vorläufigen (und unverbindlichen) Informationsaustausch über den FRAGEBOGEN, um zu sehen, ob unsere mögliche Zusammenarbeit eine ausreichende Grundlage und eine realistische Perspektive hat. Wir werden Ihre Antworten bearbeiten und uns dann bei Ihnen melden.",
            <Fragment><i>Mit der Zusendung der Antworten nehmen wir Sie auch automatisch in die Datenbank der Interessenten an einer Kooperation auf. Wir behandeln die im Fragebogen gemachten Angaben in Übereinstimmung mit dem Datenschutzgesetz (DSGVO) und verwenden sie nur zum Zweck der Zusammenarbeit.</i></Fragment>,
        ],
        questionTitle: "FRAGEBOGEN",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name und Nachname*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail*",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefon*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text_1",
                name: "contactform_text_1",
                label: "Warum möchten Sie mit Captain Candy zusammenarbeiten?",
                textarea: true,
            },
            {
                id: "contactform_text_2",
                name: "contactform_text_2",
                label: "Wie haben Sie von Captain Candy erfahren?",
                textarea: true,
            },
            {
                id: "contactform_text_3",
                name: "contactform_text_3",
                label: "Wie sind Ihre bisherigen beruflichen Erfahrungen?*",
                textarea: true,
            },
            {
                id: "file_upload",
                name: "file_upload",
                label: "Fügen Sie einen strukturierten Lebenslauf bei – CV*",
                button: "Datei auswählen"
            },
            {
                id: "contactform_text_4",
                name: "contactform_text_4",
                label: "Haben Sie Erfahrung in der Führung eines Einzelhandelsgeschäfts? Wenn ja, welche?",
                textarea: true,
            },
            {
                id: "contactform_text_5",
                name: "contactform_text_5",
                label: "Arbeiten Sie für andere Marken?*",
                textarea: true,
            },
            {
                id: "contactform_text_6",
                name: "contactform_text_6",
                label: "Haben Sie Verpflichtungen, Beschränkungen oder Wettbewerbsverbote gegenüber einer Marke oder einem Konzept?*",
                textarea: true,
            },
            {
                id: "contactform_text_7",
                name: "contactform_text_7",
                label: "Betreiben Sie oder haben Sie oder eine Ihnen nahestehende Person ein Franchise-Unternehmen?",
                textarea: true,
            },
            {
                id: "contactform_text_8",
                name: "contactform_text_8",
                label: "Wenn Sie noch kein Geschäft eröffnet haben, was veranlasst Sie dazu?",
                textarea: true,
            },
            {
                id: "contactform_text_9",
                name: "contactform_text_9",
                label: "Haben Sie ein einwandfreies Führungszeugnis und sind Sie bereit, dies nachzuweisen?*",
                textarea: true,
            },
            {
                id: "contactform_text_10",
                name: "contactform_text_10",
                label: "Inwieweit können Sie sich aktiv dem ganztägigen Geschäftsstellen-Management widmen?*",
                textarea: true,
            },
            {
                id: "contactform_text_11",
                name: "contactform_text_11",
                label: "Warum sollte Captain Candy Ihrer Meinung nach in Ihrer Stadt, Ihrem Bundesland oder Ihrer Region erfolgreich sein?",
                textarea: true,
            },
            {
                id: "contactform_text_12",
                name: "contactform_text_12",
                label: "Wo möchten Sie Ihre Geschäftsstelle eröffnen? Für welche Stadt/Städte, Metropole/n, Region/en oder Land/Länder sind Sie daran interessiert, Ihr Franchise-Unternehmen von Captain Candy zu eröffnen?*",
                textarea: true,
            },
            {
                id: "contactform_text_13",
                name: "contactform_text_13",
                label: "Haben Sie eigene oder angemietete Verkaufsräume für die Geschäftsstelle von Captain Candy? Wie groß sind Ihre Verkaufsräume, wie ist ihr Grundriss und wo befinden sie sich? Wie lange werden Ihnen die Verkaufsräume zur Verfügung stehen?",
                textarea: true,
            },
            {
                id: "contactform_text_14",
                name: "contactform_text_14",
                label: "Wie hoch sind die finanziellen Mittel (in Zahlen), die Ihnen für das Projekt mit Captain Candy zur Verfügung stehen? Wie planen Sie den Bau, die Ausstattung und den Betrieb während der Inbetriebnahme der Geschäftsstelle zu finanzieren? Welchen Anteil können Sie aus eigenen finanziellen Mitteln bereitstellen?*",
                textarea: true,
            },
        ],
        link: <Fragment>“Ich stimme den Bedingungen der <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">Verarbeitung personenbezogener Daten</Link> und den Antworten im Fragebogen zu.“</Fragment>,
        button: "ABSENDEN"
    },
};

export {
    franchisingData,
}