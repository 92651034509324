import React from 'react';
import NewBook from '../Book/NewBook';
import "./style.css";

const CaptainStory = ({ data }) => {
    const checkWidth = (window.innerWidth > 767);

    return (
        <div id="page-story" className="fade-onload">
            <header>
                <div id="book-bg">
                    <div id="mybook" className="booklet position-relative">
                        <div className='position-relative w-100 h-100'>
                            {checkWidth ? (
                                <NewBook data={data} />
                            ) : (
                                data?.map((el, index) => (
                                    <div key={`data-${index + 1}`}>
                                        {el.data}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default CaptainStory;