const sendMailData = {
    en: {
        title: "Successfully sent!",
        subTitle: "A bottle with your message in it is already on its way to us.",
        desc: "Fear not, no torture awaits, but unless you confirm your registration in the e-mail we just pinged your way, we’re under strict orders from the royal fleet to steer clear…",
        button: "Send another",
    },
    de: {
        title: "Erfolgreich gesendet!",
        subTitle: "Eine Flasche mit Ihrer Botschaft ist bereits auf dem Weg zu uns.",
        desc: "Fürchte dich nicht, es erwartet dich keine Folter, aber wenn du deine Registrierung nicht in der E-Mail bestätigst, die wir gerade an dich geschickt haben, hat uns die königliche Flotte strengen Befehl, uns fernzuhalten …",
        button: "Sende ein anderes",
    },
};

const frabchisingSentData = {
    en: {
        title: "Successfully sent!",
        subTitle: "A bottle with your message in it is already on its way to us. As soon as we get your parchment, we will hurry to answer.",
        button: "Send another",
    },
    de: {
        title: "Erfolgreich gesendet!",
        subTitle: "Eine Flasche mit Ihrer Botschaft ist bereits auf dem Weg zu uns. Sobald wir Ihr Pergament erhalten, werden wir uns beeilen, Ihnen zu antworten.",
        button: "Sende ein anderes",
    },
};

export {
    sendMailData,
    frabchisingSentData
};