import React, { useState, useEffect } from "react";
import "./style.css";

const Accordion = ({ data }) => {
  const [accordionItems, setaccordionItems ] = useState();

  const click = (i) => {
  	const newAccordion = accordionItems.map((el) => {
      if (i === el.id) {
        el.open = !el.open
        return el;
      }
      return el;
    });
    setaccordionItems(newAccordion);
  };

  useEffect(() => {
  	const accordion = [];
    data?.forEach((i) => {
      accordion.push({
        id: i.id,
        title: i.title, 
        content: i.content, 
        item: i?.item, 
        itemFooter: i?.itemFooter,
        tabel: i?.tabel,
        open: false
      });
    });
      setaccordionItems(accordion);
  }, [data]);
    
  return (
    <div className="accordion">
      {accordionItems?.map((i) => (
        <div className="item aos-init aos-animate" data-aos="fade-up" key={i.id} onClick={() => click(i.id)}>
          <div className={`button ${i.open && "open"}`}></div>
          <h2 className="heading">
            {i.title}
          </h2>
          <div className={i.open 
            ? "contentItem contentItem-open" 
            : "contentItem p-top-bottom"}
          >
            <p  className={i.open 
              ? "contentItem-text contentItem-text-open" 
              : "contentItem-text"}
            >
              {i.content}
            </p>
            {i?.item && (
              <div className="content-1500" style={{ display: "block", textAlign: "center" }}>
                {i?.item?.map((el) => (
                  <div className="item-inner">
                    <h4 className="heading">
                        {el.title}
                    </h4>
                    <ul>
                      {el?.list?.map((elemnt) => (
                        <li>{elemnt}</li>
                      ))}
                    </ul>
                  </div>
                ))}
                  <div className="clearfix"></div>
                  <h5>{i?.itemFooter}</h5> <br />
                  <div className="table-wrap">
                      {i?.tabel}
                  </div>
              </div>
            )}
            
            {/* <div className={`content-1500 accordionContent accordionContentBg ${toggleButton && "active"} `} style={{ display: "block" }}>
                {data?.item1}
                {data?.item2}
                {data?.item3}
                <div className="clearfix"></div>
                <h5>{data?.itemFooter}</h5> <br />
                <div className="table-wrap">
                    {data?.tabel}
                </div>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
